import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { default as NavLink } from "next/link"
import React from "react"

import { AnovaIcon } from "~/components/icons/AnovaIcon"
import { useUser } from "~/lib/auth/useUser"

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3.75),
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  container: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  logo: {
    width: theme.spacing(17.875),
    height: theme.spacing(5.375),
    fill: theme.palette.primary.light,
  },
  link: {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  },
  addRecipelink: {
    color: theme.palette.primary.light,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(0.375),
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.light}`,
    fontWeight: theme.typography.fontWeightBold,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  mobileDisplayName: {
    marginLeft: theme.spacing(2),
  },
  disableHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
  },
  recipeMenu: {
    marginTop: theme.spacing(3),
  },
  spinnerWrapper: {
    display: "inline",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  spinner: {
    color: "#d8d8d8",
  },
  userButton: {
    padding: 0,
    marginLeft: theme.spacing(2),
  },
  mobileUserButton: {
    padding: 0,
    "& p": {
      marginLeft: theme.spacing(1),
    },
  },
  optionsMenuPopover: {
    width: "280px",
    boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.14)",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0 0 0 9999px rgba(0,0,0,.4)",
    },
  },
  optionsMenu: {
    width: "180px",
    top: "40px !important",
    "& > ul": {
      borderRadius: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "34px !important",
    },
  },
}))

const headerMenu = [
  {
    text: "Sous Vide",
    url: "https://anovaculinary.com/pages/find-your-anova-precision-cooker",
  },
  {
    text: "Combi Oven",
    url: "https://anovaculinary.com/products/anova-precision-oven",
  },
  {
    text: "Vacuum Sealers",
    url: "https://anovaculinary.com/collections/vacuum-sealers",
  },
  {
    text: "Accessories",
    url: "https://anovaculinary.com/collections/accessories",
  },
  {
    text: "Recipes",
    url: "https://anovaculinary.com/pages/recipes",
  },
]

const logoUrl = "https://anovaculinary.com/"

export const Header = (): JSX.Element => {
  const classes = useStyles()
  const [drawer, setDrawer] = React.useState(false)
  const [showOptions, setShowOptions] = React.useState(null)
  const { user, loading, logout } = useUser(true)

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setDrawer(open)
  }

  const handleOptionsOpen = (event) => {
    setShowOptions(event.currentTarget)
  }

  const handleOptionsClose = () => {
    setShowOptions(null)
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Container className={classes.root}>
          <Grid container className={classes.container} spacing={0}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                <Grid item xs={3}>
                  <NavLink href={logoUrl} prefetch={false} passHref>
                    <a>
                      <AnovaIcon className={classes.logo} />
                    </a>
                  </NavLink>
                </Grid>
                <Grid container item direction="row" justify="flex-end" alignItems="center" xs={9}>
                  <Grid item>
                    <Box display={{ xs: "none", md: "inline" }}>
                      {headerMenu.map((menuItem, menuItemIndex) => (
                        <NavLink key={menuItemIndex} href={menuItem.url} prefetch={false} passHref>
                          <MaterialLink color="inherit" component="a" className={classes.link}>
                            {menuItem.text}
                          </MaterialLink>
                        </NavLink>
                      ))}
                      {!user && !loading && (
                        <NavLink
                          href={`https://anovaculinary.io/ali/?redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN}/signin`}
                          prefetch={false}
                          passHref
                        >
                          <MaterialLink color="inherit" component="a" className={classes.link}>
                            Sign In
                          </MaterialLink>
                        </NavLink>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display={{ xs: "none", md: "inline" }}>
                      {loading ? (
                        <div className={classes.spinnerWrapper}>
                          <CircularProgress className={classes.spinner} />
                        </div>
                      ) : user ? (
                        <>
                          <IconButton className={classes.userButton} onClick={handleOptionsOpen}>
                            {/* @ts-ignore */}
                            <Avatar alt={`${user.displayName || ""}`} src={`${user.photoURL || ""}`} />
                          </IconButton>
                          <Menu
                            id="options-menu"
                            keepMounted
                            PopoverClasses={{ paper: classes.optionsMenuPopover }}
                            anchorEl={showOptions}
                            open={Boolean(showOptions)}
                            onClose={handleOptionsClose}
                            className={classes.optionsMenu}
                          >
                            <MenuItem onClick={logout}>
                              <Typography variant="inherit">Sign Out</Typography>
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                  <Box display={{ xs: "inline", md: "none" }}>
                    <IconButton onClick={toggleDrawer(true)}>
                      <MenuIcon />
                    </IconButton>
                    <Drawer anchor={"top"} open={drawer} onClose={toggleDrawer(false)}>
                      <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <List>
                          {headerMenu.map((menuItem, menuItemIndex) => (
                            <ListItem key={menuItemIndex} button>
                              <NavLink href={menuItem.url} prefetch={false} passHref>
                                <ListItemText color="inherit" primary={menuItem.text} />
                              </NavLink>
                            </ListItem>
                          ))}
                          {loading ? (
                            <div className={classes.spinnerWrapper}>
                              <CircularProgress className={classes.spinner} />
                            </div>
                          ) : !loading && user ? (
                            <ListItem button onClick={logout}>
                              <ListItemText color="inherit" primary={"Sign Out"} />
                            </ListItem>
                          ) : (
                            <ListItem button>
                              <NavLink
                                href={`https://anovaculinary.io/ali/?redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN}/signin`}
                                prefetch={false}
                                passHref
                              >
                                <ListItemText color="inherit" primary={"Sign In"} />
                              </NavLink>
                            </ListItem>
                          )}
                        </List>
                      </Box>
                    </Drawer>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

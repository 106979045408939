type userData = {
  id: string
  displayName: string | null
  photoURL: string | null
  token: string
}

export const mapUserData = (user): userData => {
  const { uid, xa, displayName, photoURL } = user

  return {
    id: uid,
    displayName,
    photoURL,
    token: xa,
  }
}

import {
  Box,
  Container,
  Grid,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { default as NavLink } from "next/link"
import React from "react"

import { FacebookIcon } from "~/components/icons/FacebookIcon"
import { InstagramIcon } from "~/components/icons/InstagramIcon"
import { LinkedinIcon } from "~/components/icons/LinkedinIcon"
import { PinterestIcon } from "~/components/icons/PinterestIcon"
import { TwitterIcon } from "~/components/icons/TwitterIcon"

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#646464",
  },
  footer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2.5),
  },
  klaviyoForm: {
    margin: theme.spacing(0, "auto"),
    width: theme.spacing(52),
    maxWidth: "90%",
  },
  socialMediaListItem: {
    justifyContent: "center",
  },
  socialMediaIcon: {
    color: "#d8d8d8",
    width: theme.spacing(4),
    height: theme.spacing(4.5),
    margin: theme.spacing(1.5),
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  footerListItem: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: { justifyContent: "center" },
  },
  footerTitle: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
  },
  footerLink: {
    color: "#d8d8d8",
    "&:hover": {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  },
  copyright: {
    width: "inherit",
    height: theme.spacing(7.125),
    paddingTop: theme.spacing(2.5),
    backgroundColor: "#222222",
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(14.25),
      paddingBottom: theme.spacing(1),
    },
  },
  copyrightText: { color: "#959595" },
  copyrightLink: {
    color: "#959595",
    padding: theme.spacing(1, 3),
    "&:hover": {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  },
}))

const socialMediaIcons = [
  {
    icon: FacebookIcon,
    url: "https://www.facebook.com/AnovaSousVideCooking",
  },
  {
    icon: InstagramIcon,
    url: "https://www.instagram.com/anovaculinary/",
  },
  {
    icon: TwitterIcon,
    url: "https://anovaculinary.com/affiliate-program/",
  },
  {
    icon: PinterestIcon,
    url: "https://www.pinterest.com/anovaculinary/",
  },
  {
    icon: LinkedinIcon,
    url: "https://www.linkedin.com/company/3809887",
  },
]

const footerMenus = [
  {
    title: "About",
    links: [
      {
        text: "About Us",
        url: "https://anovaculinary.com/about-us/",
      },
      {
        text: "Careers",
        url: "https://anovaculinary.com/careers/",
      },
      {
        text: "Affiliate Program",
        url: "https://anovaculinary.com/about-us/",
      },
      {
        text: "Press",
        url: "https://anovaculinary.com/anova-press/",
      },
      {
        text: "Blog",
        url: "https://anovaculinary.com/blog/",
      },
    ],
  },
  {
    title: "Get Help",
    links: [
      {
        text: "Support",
        url: "https://support.anovaculinary.com/hc/en-us",
      },
      {
        text: "Community",
        url: "https://community.anovaculinary.com/",
      },
      {
        text: "Contact",
        url: "https://anovaculinary.com/contact-2/",
      },
      {
        text: "Order Lookup",
        url: "https://anovaculinary.com/pages/order-lookup/",
      },
      {
        text: "Do Not Sell My Information",
        url: "https://anovaculinary.com/do-not-sell/",
      },
    ],
  },
  {
    title: "Shop",
    links: [
      {
        text: "Products",
        url: "https://anovaculinary.com/products/",
      },
      {
        text: "Sous Vide Accessories",
        url: "https://anovaculinary.com/sous-vide-accessories/",
      },
      {
        text: "Warranty Policy",
        url: "https://support.anovaculinary.com/hc/en-us/articles/204269040-Warranty-Provision",
      },
      {
        text: "Return Policy",
        url:
          "https://support.anovaculinary.com/hc/en-us/articles/201710434-What-is-your-Returns-Refunds-and-Warranty-Policies-",
      },
    ],
  },
  {
    title: "Resources",
    links: [
      {
        text: "Recipes",
        url: "https://anovaculinary.com/recipes/",
      },
      {
        text: "What is Sous Vide",
        url: "https://anovaculinary.com/what-is-sous-vide/",
      },
      {
        text: "Anova App",
        url: "https://anovaculinary.com/app/",
      },
      {
        text: "Reviews",
        url: "https://anovaculinary.com/anova-precision-cooker/reviews/",
      },
      {
        text: "Social Impact",
        url: "https://anovaculinary.com/corporate-responsibility/",
      },
    ],
  },
]

const copyrightMenu = [
  {
    text: "Terms of Use",
    url: "https://anovaculinary.com/terms/",
  },
  {
    text: "Privacy Policy",
    url: "https://anovaculinary.com/privacy/",
  },
]

const copyrightText = `© 2013 - ${new Date().getFullYear()} Anova Applied Electronics, Inc.`

export const Footer = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Container>
        <Grid container direction="row" justify="center" className={classes.footer} spacing={0}>
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.klaviyoForm}>
              <div className="klaviyo-form-RZNsgJ" />
            </Box>
            <List dense={true}>
              <ListItem dense={true} className={classes.socialMediaListItem}>
                {socialMediaIcons.map((socialMediaIcon, index) => (
                  <NavLink key={index} href={socialMediaIcon.url} prefetch={false} passHref={true}>
                    <a>
                      <socialMediaIcon.icon className={classes.socialMediaIcon} />
                    </a>
                  </NavLink>
                ))}
              </ListItem>
            </List>
          </Grid>
          {footerMenus.map((footerMenu, menuIndex) => (
            <Grid key={menuIndex} item xs={12} sm={6} md={2}>
              <Typography variant="body2" component="div">
                <div className={classes.footerTitle}>{footerMenu.title}</div>
                <List dense={true}>
                  {footerMenu.links.map((menuItem, menuItemIndex) => (
                    <ListItem key={menuItemIndex} dense={true} className={classes.footerListItem}>
                      <NavLink href={menuItem.url} prefetch={false} passHref>
                        <MaterialLink color="inherit" className={classes.footerLink} component="a">
                          <ListItemText primary={menuItem.text} />
                        </MaterialLink>
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="flex-start" className={classes.copyright} spacing={0}>
          <Grid item>
            <Typography variant="caption">
              <span className={classes.copyrightText}>{copyrightText}</span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography noWrap variant="caption" component="div">
              {copyrightMenu.map((menuItem, menuItemIndex) => (
                <NavLink key={menuItemIndex} href={menuItem.url} prefetch={false} passHref={true}>
                  <MaterialLink color="inherit" component="a" className={classes.copyrightLink}>
                    {menuItem.text}
                  </MaterialLink>
                </NavLink>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

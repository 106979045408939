import { SvgIcon } from "@material-ui/core"

export const RatingIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <defs>
        <filter id="suyzxyjj4a" width="102.8%" height="104.4%" x="-1.4%" y="-2.2%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.615687274 0 0 0 0 0.615687274 0 0 0 0 0.615687274 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="lrgm9fyayb" width="208.3%" height="208.3%" x="-54.2%" y="-54.2%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <g filter="url(#suyzxyjj4a)" transform="translate(-371 -519) translate(345 112)">
            <g>
              <g>
                <g>
                  <g
                    filter="url(#lrgm9fyayb)"
                    transform="translate(0 393) translate(24 11) translate(0 1) translate(2 2)"
                  >
                    <path d="M6 8.635L9.09 10.5 8.27 6.985 11 4.62 7.405 4.315 6 1 4.595 4.315 1 4.62 3.73 6.985 2.91 10.5z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

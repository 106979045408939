import Head from "next/head"
import Router, { useRouter } from "next/router"
import React, { useEffect } from "react"

import { Footer } from "~/components/common/Footer"
import { Header } from "~/components/common/Header"

declare global {
  interface Window {
    analytics: any
    gtag: (event: string, ...args: any[]) => void
    dataLayer: any[]
  }
}

Router.events.on("routeChangeComplete", (url) => {
  if (window?.analytics) window.analytics.page(url)
  document.dispatchEvent(new Event("MountainReady"))
})

interface LayoutProps {
  children: React.ReactNode
  collectionTitle?: string
  searchTitle?: string
}

export const Layout = ({ children, collectionTitle, searchTitle }: LayoutProps): JSX.Element => {
  const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID
  const pageTitle = collectionTitle
    ? `${collectionTitle} | Combi Steam Oven Recipes`
    : searchTitle
    ? `${searchTitle} | Combi Steam Oven Recipes`
    : "Anova Precision™ Oven Recipes"

  const router = useRouter()
  const { pathname, search } = new URL(router.asPath, "https://oven.anovaculinary.com")

  const queryParams = new URLSearchParams(search)
  const page = queryParams.get("page")
  const query = queryParams.get("query")

  const canonicalUrl = `https://oven.anovaculinary.com${pathname}${query ? `?query=${query}` : ""}${
    page ? `&page=${page}` : ""
  }`

  useEffect(() => {
    const loadGoogleAnalytics = () => {
      const gaScript = document.createElement("script")
      gaScript.type = "text/javascript"
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      document.head.appendChild(gaScript)

      window.dataLayer = window.dataLayer || []
      window.gtag = function gtag(event, ...args) {
        window.dataLayer.push({
          event,
          ...args,
        })
      }
      window.gtag("js", new Date())
      window.gtag("config", googleAnalyticsId)
    }

    const loadKlaviyo = () => {
      const klaviyoScript = document.createElement("script")
      klaviyoScript.type = "text/javascript"
      klaviyoScript.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Kxfd42"
      document.head.appendChild(klaviyoScript)
    }

    loadGoogleAnalytics()
    loadKlaviyo()
  }, [])

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" href={canonicalUrl} hrefLang="en-us" />
      </Head>
      <Header />
      <>{children}</>
      <Footer />
    </>
  )
}

import { useRouter } from "next/router"
import React from "react"

import { firebaseAuth } from "~/config/firebaseClient"
import { mapUserData } from "~/lib/auth/mapUserData"
import { getUserFromCookie, removeUserCookie, setUserCookie } from "~/lib/auth/userCookies"

export const useUser = (skipRedirect = false) => {
  const [user, setUser] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const router = useRouter()

  const logout = async () => {
    return firebaseAuth
      .signOut()
      .then(() => {
        router.push("/")
      })
      .catch((e) => {
        console.error(e)
      })
  }

  React.useEffect(() => {
    const cancelAuthListener = firebaseAuth.onIdTokenChanged((user) => {
      if (user) {
        const userData = mapUserData(user)
        setUserCookie(userData)
        // @ts-ignore
        setUser(userData)
      } else {
        removeUserCookie()
        setUser(null)
      }
    })

    const userFromCookie = getUserFromCookie()
    if (!userFromCookie) {
      if (!skipRedirect) {
        router.push("/")
      }
      setLoading(false)
      return
    }

    setUser(userFromCookie)
    setLoading(false)

    return () => {
      cancelAuthListener()
    }
  }, [])

  return { user, loading, logout }
}

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  format,
} from "date-fns"
import { has, isEmpty } from "lodash"

export const getWebpImage = (imageUrl) => {
  const array = imageUrl.split("/")

  if (array.length < 5 || array[2].includes("google")) {
    return imageUrl
  }

  array.splice(6, 0, "f_auto")
  const webpUrl = array.join("/")

  return webpUrl
}

export const getWebpResizeImage = (imageUrl, width = 16, height = 16, settings = []) => {
  if (imageUrl && typeof imageUrl === "string") {
    const array = imageUrl.split("/")

    if (array.length < 5 || array[2].includes("google")) {
      return imageUrl
    }

    array.splice(6, 0, `w_${width},h_${height},c_fill,f_auto,q_auto,dpr_auto,${settings.join(",")}`)

    const webpUrl = array.join("/")

    return webpUrl
  }

  return imageUrl
}

export const secondsToTimeString = (seconds) => {
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = Math.floor(seconds / 60) - hours * 60

  return `${hours}`.padStart(2, "0") + ":" + `${minutes}`.padStart(2, "0")
}

export const secondsToMinutesString = (seconds) => {
  const minutes = Math.floor(seconds / 60)

  return `${minutes}`.padStart(2, "0")
}

export const toAnalyticsDateTime = (seconds: number): string => {
  if (seconds && typeof seconds === "number") {
    const date = new Date(seconds * 1000)
    const formatDate = format(date, "yyyy-MM-dd HH:mm:ss")

    return formatDate
  }

  return seconds.toString()
}

export const toDateTime = (seconds) => {
  if (seconds && typeof seconds === "number") {
    const date = new Date(seconds * 1000)
    const formatDate = format(date, "LLLL d, u")

    return formatDate
  }

  return seconds
}

export const toStrictDateTime = (seconds) => {
  if (seconds && typeof seconds === "number") {
    const date = new Date(seconds * 1000)
    const formatDate = format(date, "yyyy-MM-dd")

    return formatDate
  }

  return seconds
}

export const transformToCamelCase = (string) => {
  if (string != null) {
    return string.toLowerCase().replace(/-(.)/g, function (_, first) {
      return first.toUpperCase()
    })
  }

  return string
}

export const getStepsImage = (steps) => {
  if (isEmpty(steps)) {
    return steps
  }

  const stepsImages = steps
    .filter((step) => has(step, "photoUrl"))
    .map((step) => {
      return { url: step.photoUrl, alt: `${step.title}` }
    })

  return stepsImages
}

export const getFractionSymbol = (number) => {
  const fractionStrings = {
    "0.13": "⅛",
    "0.25": "¼",
    "0.33": "⅓",
    "0.5": "½",
    "0.67": "⅔",
    "0.75": "¾",
  }
  const fraction = fractionStrings[number]

  if (fraction) {
    return fraction
  }

  return number
}

export const simpleTimeSinceDateToString = (timestamp) => {
  const commentDate = new Date(timestamp._seconds * 1000)
  const now = Date.now()

  const diffSeconds = differenceInSeconds(now, commentDate)
  if (diffSeconds < 60) {
    return "Just now"
  }

  const diffMinutes = differenceInMinutes(now, commentDate)
  if (diffMinutes < 60) {
    return `${diffMinutes}m`
  }

  const diffHours = differenceInHours(now, commentDate)
  if (diffHours < 24) {
    return `${diffHours}h`
  }

  const diffDays = differenceInDays(now, commentDate)
  if (diffDays < 7) {
    return `${diffDays}d`
  }

  const diffWeeks = differenceInWeeks(now, commentDate)
  if (diffWeeks < 5) {
    return `${diffWeeks}w`
  }

  const diffMonths = differenceInMonths(now, commentDate)
  if (diffMonths < 12) {
    return `${diffMonths}mo`
  }

  const diffYears = differenceInYears(now, commentDate)
  return `${diffYears}y`
}

export const loadProfileNameAndPhoto = async (userDocRef) => {
  try {
    const userProfileDocumentSnapshot = await userDocRef.get()

    const { name, photoUrl } = userProfileDocumentSnapshot.data()

    return {
      name,
      photoUrl,
    }
  } catch (error) {
    return {
      name: "",
      photoUrl: "",
    }
  }
}

export const fetcher = async (config) => {
  const response = await fetch(config)

  return response.json()
}

export const commentHeaderText = (numberOfComments) => {
  if (numberOfComments === 0) {
    return `0`
  } else if (numberOfComments === 1) {
    return `1 Comment`
  } else {
    return `${numberOfComments} Comments`
  }
}

export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

import { SvgIcon } from "@material-ui/core"

export const AnovaIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 200 60">
      <g fillRule="evenodd">
        <path d="M156.221 46.425l13.915-32.285h6.423l13.916 32.285h-7.488l-9.64-21.55-9.638 21.55h-7.488M131.595 14.14l9.652 21.555 9.641-21.555h7.489L144.46 46.425h-6.422l-13.915-32.242 7.47-.043M108.066 39.956c3.568 0 5.353-1.794 5.353-5.38v-8.608c0-3.586-1.785-5.38-5.353-5.38h-9.633c-2.855 0-4.282 1.794-4.282 5.38v8.608c0 3.586 1.784 5.38 5.352 5.38h8.563zm1.07 6.448l-10.703.021v-.021c-7.137 0-10.704-3.585-10.704-10.754V24.895c0-7.17 3.567-10.755 10.704-10.755h10.704c7.136 0 10.704 3.585 10.704 10.755V35.65c0 7.155-3.568 10.74-10.704 10.754zM49.193 46.425V14.14h6.422l19.268 21.517V14.14h6.423v32.285h-6.397L55.615 24.88v21.546h-6.422M23.916 14.14h6.422l13.916 32.285h-7.489l-9.638-21.55-9.639 21.55H10z" />
      </g>
    </SvgIcon>
  )
}
